import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";

import BlogList from "../sections/blog/BlogListSidebar";
import Sidebar from "../sections/blog/Sidebar";
import { graphql } from "gatsby";

const _ = require("lodash");

const Blog = ({ data }) => {
  const { page } = data;
  const [posts, setPosts] = useState([]);

  const popularPosts = data.posts.nodes.filter((item) =>
    data.page.frontmatter.popularPosts.includes(item.frontmatter.postid)
  );

  useEffect(() => {
    setPosts(data.posts.nodes);
  }, [data]);

  // Tag pages:
  let tags = [];
  // Iterate through each post, putting all found tags into `tags`
  data.tags.nodes.forEach((edge) => {
    if (_.get(edge, `frontmatter.tags`)) {
      tags = tags.concat(edge.frontmatter.tags);
    }
  });
  // Eliminate duplicate tags
  tags = _.uniq(tags).filter((e) => e);

  return (
    <>
      <PageWrapper title="Blog">
        <Section className="pb-0">
          <div className="pt-5" />
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Title variant="hero">{page.frontmatter.title}</Title>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: page.frontmatter.subtitle,
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Row>
              <Col lg="8" className="order-lg-2 mb-5">
                <BlogList data={posts} />
              </Col>
              <Col lg="4" className="order-lg-1">
                <Sidebar
                  posts={data.posts.nodes}
                  setPosts={setPosts}
                  tags={tags}
                  popularPosts={popularPosts}
                />
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Blog;

export const query = graphql`
  query BlogPage {
    page: markdownRemark(fileAbsolutePath: { regex: "/blog-page.md/" }) {
      frontmatter {
        title
        subtitle
        popularPosts
      }
    }
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/blog//" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          postid
          tags
          date(formatString: "MMMM Do YYYY")
          description
          featuredimage {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    tags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/blog//" } }
    ) {
      nodes {
        frontmatter {
          tags
        }
      }
    }
  }
`;
