import React from "react";

import {
  CardSidebar,
  Block,
  TitleSidebar,
  TitlePost,
  Date,
  CatList,
  CatListItem,
} from "../../components/Sidebar";

import InputSearch from "../../components/InputSearch";

const _ = require("lodash");

const Sidebar = ({ posts, setPosts, tags, popularPosts }) => {
  const popularPostsLength = popularPosts.length;
  // fired when user searches through posts
  const handleInputChange = (event) => {
    const query = event.target.value || "";

    // return all filtered posts
    const filteredData = posts.filter((post) => {
      // destructure data from post frontmatter
      const { title, description, date } = post.frontmatter;
      return (
        // standardize data with .toLowerCase()
        // return true if the description, title or tags
        // contains the query string
        (title && title.toLowerCase().includes(query.toLowerCase())) ||
        (description &&
          description.toLowerCase().includes(query.toLowerCase())) ||
        (date && date.toLowerCase().includes(query.toLowerCase()))
      );
    });
    // update state according to the latest query and results
    setPosts(
      filteredData // with filtered data from posts.filter(post => (//filteredData)) above
    );
  };

  return (
    <>
      <CardSidebar p="15px" pl="20px">
        <form>
          <InputSearch onChange={handleInputChange} />
        </form>
      </CardSidebar>
      <CardSidebar>
        <TitleSidebar>Popular Posts</TitleSidebar>
        {popularPosts.map((item, index) => {
          const styling = index + 1 === popularPostsLength && {
            borderBottom: "none",
            pb: "0",
          };
          return (
            <Block {...styling} key={`${item.frontmatter.title}-${index}`}>
              <TitlePost link={item.fields.slug}>
                {item.frontmatter.title}
              </TitlePost>
              <Date>{item.frontmatter.date}</Date>
            </Block>
          );
        })}
      </CardSidebar>

      <CardSidebar>
        <TitleSidebar mb="28px">Categories</TitleSidebar>
        <CatList>
          {tags.map((item) => {
            const countTaggedPosts = posts.filter((post) =>
              post.frontmatter.tags?.includes(item)
            ).length;

            return (
              <CatListItem
                numPosts={countTaggedPosts}
                link={`/tags/${_.kebabCase(item)}/`}
                key={`/tags/${_.kebabCase(item)}/`}
              >
                {item}
              </CatListItem>
            );
          })}
        </CatList>
      </CardSidebar>
    </>
  );
};
export default Sidebar;
